import React from "react";
import moment from "moment";

import yup from "@services/yup";
import { notifMessage, pageSizeOptions } from "@config/collection";

export const PROSPECT = "PROSPECT";
export const SHORT_LIST = "SHORT_LIST";
export const SCREEN = "SCREEN";
export const MANAGER_REVIEW = "MANAGER_REVIEW";
export const MGR_REVIEW = "MGR SCREEN";
export const INTERVIEW = "INTERVIEW";
export const OFFER = "OFFER";
export const HIRE = "HIRE";
export const DISQUALIFIED = "DISQUALIFIED";
export const PENDING = "PENDING";
export const ACTIVE = "ACTIVE";
export const DNQ = "DNQ";
export const DRAFT = "DRAFT";
export const OPEN = "OPEN";
export const ON_HOLD = "ON_HOLD";
export const CANCELED = "CANCELED";
export const CLOSED = "CLOSED";
export const CSV_EXPORT = "CSV_EXPORT";
export const ATS_EXPORT = "ATS_EXPORT";
export const ATS_EXPORT_NOT_CONFIGURED = "ATS_EXPORT_NOT_CONFIGURED";
export const ATS_EXPORT_NOT_ALLOWED = "ATS_EXPORT_NOT_ALLOWED";
export const COMPANY_LIMIT_REACHED = "COMPANY_LIMIT_REACHED";
export const USER_LIMIT_REACHED = "USER_LIMIT_REACHED";
export const REQUI_FIELD_REQUIRED = "REQUI_FIELD_REQUIRED";
export const REQUISITIONS = "REQUISITIONS";
export const ATS_JOBS = "ATS_JOBS";

export const stageFields = ["stage", "disqualified", "status", "reason"];

export const fieldMapping = {
  name: "Requisition Name",
  recruiter: "Recruiter",
  reference: "Internal Requisition ID",
  department: "Internal Company Department",
  jobTitle: "Requisition Job Title",
};

const strLen = 100;
const stringSchema = yup
  .string()
  .trim()
  .trim();

export const validationSchema = {
  isBig: yup.boolean(),
  name: stringSchema
    .max(
      150,
      "You have exceeded the maximum character limit. Please modify your requisition name."
    )
    .label(fieldMapping.name),
  reference: stringSchema
    .max(strLen)
    .nullable()
    .label(fieldMapping.reference),
  department: stringSchema
    .max(strLen)
    .nullable()
    .label(fieldMapping.department),
  recruiter: yup
    .mixed()
    .required()
    .label(fieldMapping.recruiter),
  sourcer: yup.mixed(),
  hiringManager: yup.mixed(),
  hotRequisition: yup.boolean(),
  jobTitle: yup
    .string()
    .max(strLen)
    .nullable()
    .trim()
    .required()
    .label(fieldMapping.jobTitle),
};

export const defaultRequisition = {
  name: "",
  reference: "",
  department: "",
  sourcer: null,
  recruiter: null,
  hiringManager: null,
  hotRequisition: false,
  openedDate: moment(),
  hireStartDate: null,
  closedDate: null,
  requiredByDate: null,
  status: "OPEN",
  jobTitle: "",
};

export const defaultQueryParams = {
  stage: PROSPECT,
  page: 1,
  size: pageSizeOptions[1],
  sortField: "matchedSkillsCount",
  sortOrder: "desc",
  includeExported: true,
  status: undefined,
  contactInfo: [],
  contactRequest: [],
  commStatus: [],
};

export const defaultReqListPages = {
  [DRAFT]: 1,
  [OPEN]: 1,
  [ON_HOLD]: 1,
  [CANCELED]: 1,
  [CLOSED]: 1,
};
export const defaultReqsByStatus = {
  [DRAFT]: {},
  [OPEN]: {},
  [ON_HOLD]: {},
  [CANCELED]: {},
  [CLOSED]: {},
};

export const statuses = {
  [DRAFT]: "Draft",
  [OPEN]: "Open",
  [ON_HOLD]: "On Hold",
  [CANCELED]: "Canceled",
  [CLOSED]: "Closed",
};

export const statusCategories = {
  [PENDING]: "Pending",
  [ACTIVE]: "Active",
  [DNQ]: "DNQ",
};

export const stages = {
  [PROSPECT]: "Prospect",
  [SHORT_LIST]: "Short List",
  [SCREEN]: "Screen",
  [MANAGER_REVIEW]: "Manager Screen",
  [INTERVIEW]: "Interview",
  [OFFER]: "Offer",
  [HIRE]: "Hire",
  [DISQUALIFIED]: "Disqualified",
};

export const stagesWithoutDnq = {
  [PROSPECT]: "Prospect",
  [SHORT_LIST]: "Short List",
  [SCREEN]: "Screen",
  [MANAGER_REVIEW]: "Manager Screen",
  [INTERVIEW]: "Interview",
  [OFFER]: "Offer",
  [HIRE]: "Hire",
};

export const dnqReasons = {
  CULTURE: "Culture",
  POSITION: "Position",
  COMPENSATION: "Compensation",
  LOCATION: "Location",
  EXPERIENCE_GAP: "Experience Gap",
  SKILL_GAP: "Skill Gap",
};

export const exportErrors = {
  [ATS_EXPORT_NOT_CONFIGURED]: "ATS export has not been configured.",
  [ATS_EXPORT_NOT_ALLOWED]: "Unlock Contact Request by upgrading your plan.",
  [COMPANY_LIMIT_REACHED]: (
    <>
      Total Export limit exceeded for your company.
      <br />
      Please contact your company administrator.
    </>
  ),
  [USER_LIMIT_REACHED]: "Total Export limit exceeded for your account.",
  [REQUI_FIELD_REQUIRED]:
    "Please select a requisition associated with this job.",
  INTERNAL_ERROR:
    "An error occurred while exporting the candidate(s). Please contact support for more information.",
};

export const exportOptions = {
  [ATS_EXPORT]: {
    title: "EXPORT CANDIDATES TO ATS",
  },
  [CSV_EXPORT]: {
    title: "EXPORT CANDIDATES AS CSV",
  },
};

export const DEFAULT_ERROR = "DEFAULT_ERROR";
export const NOT_FOUND = "NOT_FOUND";
export const MISSING_PAGE = "MISSING_PAGE";

export const errorMessage = {
  [MISSING_PAGE]:
    "You were redirected to first page as the page you requested is no longer available.",
  [NOT_FOUND]: "Resource Not Found",
  [DEFAULT_ERROR]: `${notifMessage.internalError} Please try again.`,
};

export default {
  dnqReasons,
  statuses,
  statusCategories,
  defaultRequisition,
  validationSchema,
  stages,
  errorMessage,
};
