import { createSelector } from "reselect";

export const userStateSelector = (state) => state.user;

export const userDataSelector = createSelector(
  userStateSelector,
  (user) => user.data
);

export const licenseSelector = createSelector(
  userStateSelector,
  (user) => user?.license
);

export const autoDialer = createSelector(
  licenseSelector,
  (license) => license?.autoDialer
);
