import resource from "@config/resource";
import platformApiSvc from "@services/platform-api/";
import _get from "lodash.get";

import yup from "@services/yup";
import { EMAIL } from "@config/task";

export const lastSignOnKey = "lastSignOn";

export const fieldMapping = {
  manager: "Manager",
  username: "Username",
  firstName: "First Name",
  lastName: "Last Name",
  company: "Company",
  phone: "Phone",
  cell: "Cell",
  email: "Email",
  active: "Active",
  securityRoles: "Role access",
  allowedDepartments: "Department Access",
  isManager: "Manager",
  isHiringManager: "Hiring Manager",
  excludeCurrentReq: "Current Req",
  excludeOtherReq: "Other Active Reqs",
  excludeExported: "Exclude Exported",
  licensedUserCount: "Licensed Users",
  taskType: "Task Type",
  countries: "Country Access",
};

export const defaultDocumentValue = {
  manager: "",
  username: "",
  firstName: "",
  lastName: "",
  phone: {
    country: "US",
    number: "",
  },
  cell: {
    country: "US",
    number: "",
  },
  email: "",
  active: true,
  securityRoles: [],
  isManager: false,
  isHiringManager: false,
  userDefaults: {
    search: {
      excludeCurrentReq: true,
      excludeOtherReq: false,
      excludeOtherReqStage: null,
      excludeExported: true,
    },
    task: {
      type: EMAIL,
    },
  },
  allowedDepartments: [],
  countries: [],
};

const isUniqueUsername = (validateDuplicateUsername, excludeId, schema) => {
  return validateDuplicateUsername
    ? schema.test({
        name: "isUniqueUsername",
        message:
          "${path} already exists. Enter a new name for the user account.", // eslint-disable-line
        async test(username) {
          if (!username) {
            return true;
          }

          try {
            const resp = await platformApiSvc.get(
              `${resource.accounts}/search/checkIfExists`,
              {
                params: { username, excludeId },
              }
            );
            return !_get(resp, "data.exists");
          } catch (e) {
            return false;
          }
        },
      })
    : schema;
};

const isValidDepartments = (eds, companyDepartments, schema) => {
  return eds
    ? schema.required().test({
        name: "isValidDepartments",
        message:
          "The ${path} is no longer available. Please check and reselect department access.", // eslint-disable-line
        async test(departments) {
          const hasInvalidDepartments =
            eds &&
            companyDepartments &&
            departments.some((dept) => companyDepartments.indexOf(dept) === -1);
          return !hasInvalidDepartments;
        },
      })
    : schema;
};

const isValidSecRole = (excludedSecRoles, schema) => {
  return excludedSecRoles.length
    ? schema.test({
        name: "isValidSecRole",
        message:
          "The ${path} is no longer available. Please check and reselect the role access.", // eslint-disable-line
        async test(secRoles) {
          const hasInvalidDepartments = secRoles.some(
            (value) => excludedSecRoles.indexOf(value) > -1
          );
          return !hasInvalidDepartments;
        },
      })
    : schema;
};

const shortText = () =>
  yup
    .string()
    .max(50)
    .trim();
const contactNumSchema = yup
  .object()
  .shape({
    country: yup.string(),
    number: yup.string(),
  })
  .transform((value) => {
    const { country, number } = value;
    return {
      country,
      number: number.replace(/[^0-9]+/g, ""),
    };
  });

export const validationSchema = yup.object({
  manager: yup.mixed(),
  username: yup
    .string()
    .max(15)
    .trim()
    .required()
    .matches(/^[a-z0-9]+$/i, {
      excludeEmptyString: true,
      message:
        "${path} cannot contain any special characters. Enter a new username for the User Account.", // eslint-disable-line
    })
    .when(["$validateDuplicateUsername", "id"], isUniqueUsername)
    .label(fieldMapping.username),
  firstName: shortText()
    .required()
    .label(fieldMapping.firstName),
  lastName: shortText()
    .required()
    .label(fieldMapping.lastName),
  phone: contactNumSchema,
  cell: contactNumSchema,
  email: yup
    .string()
    .max(100)
    .trim()
    .required("An email is required. Please enter an email address.")
    .email(
      "You have entered an invalid email. Please add a valid email address"
    )
    .label(fieldMapping.email),
  active: yup.boolean().label(fieldMapping.active),
  securityRoles: yup
    .array()
    .required()
    .min(1, "${path} is required") // eslint-disable-line
    .when(["$excludedSecRoles"], isValidSecRole)
    .label(fieldMapping.securityRoles),
  allowedDepartments: yup
    .array()
    .when(["$enforceDataSecurity", "$companyDepartments"], isValidDepartments)
    .label(fieldMapping.allowedDepartments),
  isManager: yup.boolean(),
  isHiringManager: yup.boolean(),
  userDefaults: yup.mixed(),
  id: yup.mixed().notRequired(),
  countries: yup.mixed().notRequired(),
  emailVerificationStatus: shortText().nullable(),
});

export const userProfileValidationSchema = yup.object({
  manager: yup.mixed(),
  firstName: shortText()
    .required()
    .label(fieldMapping.firstName),
  lastName: shortText()
    .required()
    .label(fieldMapping.lastName),
  phone: contactNumSchema,
  cell: contactNumSchema,
  email: yup
    .string()
    .max(100)
    .trim()
    .required()
    .email()
    .label(fieldMapping.email),
  userDefaults: yup.mixed(),
});

export default {
  fieldMapping,
  defaultDocumentValue,
  validationSchema,
  userProfileValidationSchema,
};
