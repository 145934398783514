import React, { PureComponent, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import _flow from "lodash.flow";

import { componentUpdatedValues } from "@helpers/component";
import { hhPrefixer } from "@helpers/collection";
import { MODULES } from "@config/constants";
import appConfig from "@config/app";
import appRoutes from "@config/app-routes";
import authSvc from "@services/auth/";
import {
  ROLE_MENU_ACCESS_APP_ADMIN,
  ROLE_MENU_ACCESS_ACCOUNT_ADMIN,
  ROLE_MENU_ACCESS_FULFILL_CONTACT_REQUEST,
} from "@config/authorities";
import Avatar from "@components/shared/Avatar/Avatar";
import GroupNav from "@components/shared/GroupNav/GroupNav";
import TutorialNavLinks from "@components/Tutorial/NavLinks/NavLinks";
import { NavItem, DefaultNavItems, navItems } from "./nav";

const hhClass = hhPrefixer(["layout-header-default", "fc-prim-gray-l1"], true);
const tutorialModalClass = "tutorial-modal-cont";

export class SubNav extends PureComponent {
  onToggleHandler = () => {
    const { expand, onExpand } = this.props;
    if (onExpand) {
      onExpand(!expand);
    }
  };

  render() {
    const { label, links, expand } = this.props;
    const icon = expand ? "hhi-expand-less" : "hhi-expand-more";

    return (
      <div className="sub-nav">
        <a onClick={this.onToggleHandler}>
          {label}
          <span className={classnames("status", icon)} />
        </a>
        <div className={classnames("menu", { expand })}>{links}</div>
      </div>
    );
  }
}

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expandAppAdmin: false,
      expandAccountAdmin: false,
      showMenu: false,
      tutorialModal: {
        visible: false,
        key: "",
      },
    };
    this.userNavRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { showMenu } = this.state;
    const [propLocationKey, prevPropLocationKey] = componentUpdatedValues(
      this.props,
      prevProps,
      "location.key"
    );

    if (showMenu && propLocationKey !== prevPropLocationKey) {
      this.toggleMenu();
    }
  }

  closeUserNavEvent = (event) => {
    const { target } = event;
    if (this.userNavRef.current && !this.userNavRef.current.contains(target)) {
      this.toggleMenu();
    }
  };

  toggleMenu = () => {
    this.setState(
      {
        showMenu: !this.state.showMenu,
        expandAppAdmin: false,
        expandAccountAdmin: false,
      },
      () => {
        const { showMenu } = this.state;
        if (showMenu) {
          document.addEventListener("click", this.closeUserNavEvent);
        } else {
          document.removeEventListener("click", this.closeUserNavEvent);
        }
      }
    );
  };

  redirect = (path, showMenu = false) => {
    const { history } = this.props;
    history.push(path);

    this.setState({
      showMenu,
      expandAppAdmin: false,
      expandAccountAdmin: false,
    });
    if (!showMenu) {
      document.removeEventListener("click", this.closeUserNavEvent);
    }
  };

  toggleAppAdminMenu = () => {
    this.setState(({ expandAppAdmin }) => {
      return { expandAppAdmin: !expandAppAdmin };
    });
  };

  toggleAccountAdminMenu = () => {
    this.setState(({ expandAccountAdmin }) => {
      return { expandAccountAdmin: !expandAccountAdmin };
    });
  };

  renderHhAdminMenu() {
    const { expandAppAdmin } = this.state;
    let appAdminMenu = [];
    if (
      this.props.userData.companyAccount.internal &&
      authSvc.hasAccess(ROLE_MENU_ACCESS_APP_ADMIN)
    ) {
      appAdminMenu.push({
        label: "Security Roles",
        to: appRoutes.securityRole,
      });

      // appAdminMenu.push({
      //   label: 'License Plans',
      //   to: appRoutes.licensePlan
      // });
    }

    appAdminMenu = appAdminMenu.map(({ label, ...props }) => (
      <NavLink {...props} key={label}>
        {label}
      </NavLink>
    ));

    return appAdminMenu.length ? (
      <SubNav
        label="App Admin"
        links={appAdminMenu}
        onExpand={this.toggleAppAdminMenu}
        expand={expandAppAdmin}
      />
    ) : null;
  }

  renderAccoutAdminMenu() {
    const { expandAccountAdmin } = this.state;
    let accountAdminMenu = [];
    if (authSvc.hasAccess(ROLE_MENU_ACCESS_ACCOUNT_ADMIN)) {
      // accountAdminMenu.push({
      //   label: 'User Account',
      //   to: '/no-page'
      // });

      accountAdminMenu.push({
        label: "Company Account",
        to: appRoutes.userAccount,
      });
    }

    accountAdminMenu = accountAdminMenu.map(({ label, ...props }) => (
      <NavLink {...props} key={label}>
        {label}
      </NavLink>
    ));

    return accountAdminMenu.length ? (
      <SubNav
        label="Account Admin"
        links={accountAdminMenu}
        onExpand={this.toggleAccountAdminMenu}
        expand={expandAccountAdmin}
      />
    ) : null;
  }

  renderHhMenu() {
    return (
      authSvc.hasAccess(ROLE_MENU_ACCESS_FULFILL_CONTACT_REQUEST) && (
        <div>
          <NavLink to={appRoutes.fulfillRequest}>Fulfill Requests</NavLink>
        </div>
      )
    );
  }

  onPageNavigate = (e) => {
    const href = e.target.getAttribute("href");
    if (href === this.props.location.pathname) {
      e.preventDefault();
    }
  };

  render() {
    const { showMenu: expand } = this.state;
    const { isAuth, className, userData } = this.props;
    const { firstName, lastName, companyAccount } = userData;
    const statusIcon = expand ? "hhi-expand-less" : "hhi-expand-more";
    const appAdminLinks = [];
    const isLegalModule =
      companyAccount?.moduleName?.[0] === MODULES.WITNESS_FINDER;

    let mainNav = null;
    let userNav = (
      <div className="nav-links">
        <NavItem to="/login">Login</NavItem>
      </div>
    );

    if (isAuth) {
      userNav = null;

      const Nav = navItems[companyAccount?.moduleName];

      mainNav = (
        <div className="nav-links">
          <DefaultNavItems />
          <Nav />
        </div>
      );

      if (
        authSvc.hasAccess(ROLE_MENU_ACCESS_ACCOUNT_ADMIN) &&
        this.props.userData.companyAccount.internal &&
        authSvc.hasAccess(ROLE_MENU_ACCESS_APP_ADMIN)
      ) {
        appAdminLinks.push(
          <div key="hh-account-admin">
            <NavLink to={appRoutes.hhUser}>HH Account Admin</NavLink>
          </div>
        );
      }

      if (firstName) {
        const ln = lastName.substring(0, 1);
        const fn = firstName.substring(0, 1);

        userNav = (
          <Fragment>
            <div className="dtls" onClick={this.toggleMenu}>
              <Avatar text={fn + ln} className="rounded-circle" />
              <span className="name">{`${firstName} ${ln}.`}</span>
              <span className={classnames("status-icon", statusIcon)} />
            </div>
            <div className={classnames("root-menu", { expand })}>
              <div>
                <NavLink to={appRoutes.profile}>My Profile</NavLink>
              </div>
              {this.renderHhAdminMenu()}
              {appAdminLinks}
              {this.renderAccoutAdminMenu()}
              {this.renderHhMenu()}
              <div>
                <NavLink to={appRoutes.logout + "?notif=true"}>Logout</NavLink>
              </div>
            </div>
          </Fragment>
        );
      }
    }

    return (
      <div className={classnames(hhClass["layout-header-default"], className)}>
        <div className="fixed">
          <div className="center">
            {mainNav}
            <NavLink to="/" className="logo">
              {process.env.APP_NAME}
            </NavLink>
            <div ref={this.userNavRef} className="user-nav">
              {userNav}
            </div>
            <GroupNav
              className={classnames("help", {
                "d-none": isLegalModule,
              })}
              placement="bottom-right"
              trigger={<span className="hhi-help-circle" />}
            >
              <a
                className="nav-item"
                target="_blank"
                rel="noopener noreferrer"
                href={appConfig.hhWebsite + "/faqs.html"}
              >
                FAQs
              </a>
              {isAuth && (
                <TutorialNavLinks modalContainer={tutorialModalClass} />
              )}
              <div
                className="nav-item contact-support"
                href={`mailto:${appConfig.supportEmail}`}
              >
                <div>CONTACT SUPPORT</div>
                <a href={`mailto:${appConfig.supportEmail}`}>
                  {appConfig.supportEmail}
                </a>
              </div>
            </GroupNav>
            <div className={tutorialModalClass} />
          </div>
        </div>
      </div>
    );
  }
}

const stateToProps = (state) => {
  return {
    userData: state.user.data,
  };
};

export default _flow([withRouter, connect(stateToProps)])(Header);
