import { takeLatest, all, put } from "redux-saga/effects";
import Axios from "axios";
import { handleApiError } from "@helpers/collection";
import _get from "lodash.get";

import resource from "../../config/resource";
import { ACTIVATION_STATUS } from "../../config/auto-dialer/activation";
import { SUCCESS, FAIL, LOADING, IDLE } from "../../config/constants";
import * as actions from "../actions/auto-dialer/";
import platformApi from "@services/platform-api/";
import actionTypes from "@store/actions/auto-dialer/actionTypes";
import AutoDialerService from "@services/auto-dialer";
import { configSteps, urlConfigMapping } from "@config/auto-dialer/index";
import { notifMessage } from "../../config/collection";

function* getConfig(args) {
  const { companyId } = args;

  yield put(
    actions.setConfig({
      getConfig: {
        status: LOADING,
        error: null,
      },
    })
  );

  const state = {
    config: {},
    getConfig: {},
  };

  try {
    const { data } = yield platformApi.get(
      resource.autoDialer + "/configuration/full",
      {
        params: {
          company: companyId,
        },
      }
    );
    state.config = data;
    state.steps = AutoDialerService.getStepDetails(data);
    state.getConfig = {
      status: IDLE,
      error: null,
    };
  } catch (e) {
    state.getConfig = {
      status: IDLE,
      error: FAIL,
    };
  }

  yield put(actions.setConfig(state));
}

function* saveConfig(args) {
  const { companyId, config, step = "" } = args;
  const state = {};

  try {
    const { data } = yield platformApi.put(
      resource.autoDialer + urlConfigMapping[step],
      config,
      {
        params: {
          company: companyId,
        },
      }
    );
    state.steps = AutoDialerService.getStepDetails(data, step);
    state.config = data;
    state.saveConfig = {
      status: SUCCESS,
      error: null,
    };
  } catch (e) {
    state.saveConfig = {
      status: FAIL,
      error: Axios.isAxiosError(e) ? e.toJSON() : e,
    };
  }

  return yield state;
}

function* saveMessageTemplates(args) {
  const { companyId, defaultTemplateId } = args.config;
  const state = {};

  try {
    const { data } = yield platformApi.patch(
      resource.autoDialer +
        `/configuration/messageTemplate/default/${defaultTemplateId}?company=${companyId}`
    );

    state.config = data;
    state.saveConfig = {
      status: SUCCESS,
      error: null,
    };
  } catch (e) {
    state.saveConfig = {
      status: FAIL,
      error: Axios.isAxiosError(e) ? e.toJSON() : e,
    };
  }

  return yield state;
}

function* saveActiveStatus({ step, config, companyId }) {
  const state = {};

  try {
    const { data } = yield platformApi.put(
      resource.autoDialer + `/configuration/activate`,
      null,
      {
        params: {
          company: companyId,
          active: config.active,
        },
      }
    );

    state.steps = AutoDialerService.getStepDetails(data, step);
    state.config = data;
    state.saveConfig = {
      status: config.active
        ? ACTIVATION_STATUS.SUCCESSFUL_ACTIVATION
        : ACTIVATION_STATUS.SUCCESSFUL_DEACTIVATION,
      error: null,
    };
  } catch (e) {
    state.saveConfig = {
      status: FAIL,
      error: Axios.isAxiosError(e) ? e.toJSON() : e,
    };
  }

  return yield state;
}

function* saveConfigMapper(args) {
  const saveMethods = {
    [configSteps.MESSAGE_TEMPLATES]: saveMessageTemplates,
    [configSteps.ACTIVATION]: saveActiveStatus,
  };

  const func = saveMethods[args.step];

  yield put(
    actions.setSaveConfig({
      saveConfig: {
        status: LOADING,
        error: null,
      },
    })
  );

  const state = yield func ? func(args) : saveConfig(args);

  yield put(actions.setSaveConfig(state));
}

function* saveMessageTemplate(args) {
  const { companyId, data } = args;
  yield put(
    actions.setSaveMessageTemplate({
      saveMessageTemplate: {
        status: LOADING,
        error: null,
      },
    })
  );
  const state = {};

  try {
    let method = "POST";
    let urlPath = "/configuration/messageTemplate";

    if (data.id) {
      method = "PUT";
      urlPath += "/" + data.id;
    }
    yield platformApi({
      method,
      url: resource.autoDialer + urlPath,
      data: args.data,
      params: {
        company: companyId,
      },
    });
    state.saveMessageTemplate = {
      status: SUCCESS,
      error: null,
    };
  } catch (e) {
    handleApiError(e, () => {
      const data = _get(e, "response.data");
      let error =
        data?.error === "EMAIL_TEMPLATE_NAME_ALREADY_EXISTS"
          ? "DUPLICATE"
          : "FAILED";
      state.saveMessageTemplate = {
        error,
        status: FAIL,
      };
    });
  }

  yield put(actions.setSaveMessageTemplate(state));
}

function* deleteMessageTemplate(args) {
  const { id } = args;
  yield put(
    actions.setDeleteMessageTemplate({
      deleteMessageTemplate: {
        status: LOADING,
      },
    })
  );
  const state = {};

  try {
    yield platformApi.delete(resource.autoDialer + "/messageTemplate/" + id);

    state.deleteMessageTemplate = {
      status: SUCCESS,
    };
  } catch (e) {
    handleApiError(e, () => {
      state.deleteMessageTemplate = {
        status: FAIL,
      };
    });
  }

  yield put(actions.setDeleteMessageTemplate(state));
}

function* getUserMessageTemplates(args) {
  const { companyId } = args;
  yield put(
    actions.setUserMessageTemplates({
      getUserMessageTemplates: {
        status: LOADING,
      },
    })
  );
  const state = {};

  try {
    const { data } = yield platformApi.get(
      resource.autoDialer + "/user/messageTemplate",
      {
        params: {
          company: companyId,
        },
      }
    );

    state.userMessageTemplates = data;
    state.getUserMessageTemplates = {
      status: SUCCESS,
    };
  } catch (e) {
    handleApiError(e, () => {
      state.userMessageTemplates = {
        status: FAIL,
      };
    });
  }

  yield put(actions.setUserMessageTemplates(state));
}

function* startSaveUserMessageTemplate(args) {
  const { companyId, data } = args;
  yield put(
    actions.setSaveUserMessageTemplate({
      saveMessageTemplate: {
        status: LOADING,
        error: null,
      },
    })
  );
  const state = {};

  try {
    let method = "POST";
    let urlPath = "/user/messageTemplate";

    if (data.id) {
      method = "PUT";
      urlPath += "/" + data.id;
    }
    yield platformApi({
      method,
      url: resource.autoDialer + urlPath,
      data: args.data,
      params: {
        company: companyId,
      },
    });
    state.saveMessageTemplate = {
      status: SUCCESS,
      error: null,
    };
  } catch (e) {
    handleApiError(e, () => {
      const data = _get(e, "response.data");
      let error =
        data?.error === "EMAIL_TEMPLATE_NAME_ALREADY_EXISTS"
          ? "DUPLICATE"
          : "FAILED";
      state.saveMessageTemplate = {
        error,
        status: FAIL,
      };
    });
  }

  yield put(actions.setSaveUserMessageTemplate(state));
}

function* startBulkSchedule(args) {
  const { payload } = args;

  yield put(
    actions.setBulkSchedule({
      bulkSchedule: {
        status: LOADING,
        error: null,
      },
    })
  );

  const state = {};

  try {
    yield platformApi.post(resource.autoDialer + "/bulk", payload);
    state.bulkSchedule = {
      status: SUCCESS,
      error: null,
    };
  } catch (e) {
    handleApiError(e, () => {
      const message = _get(
        e,
        "response.data.errorDescription",
        notifMessage.internalError + " Auto dialer failed"
      );
      state.bulkSchedule = {
        message,
        status: FAIL,
      };
    });
  }

  yield put(actions.setBulkSchedule(state));
}

export default function* sagas() {
  yield all([
    takeLatest(actionTypes.AUTO_DIALER_GET_CONFIG, getConfig),
    takeLatest(actionTypes.AUTO_DIALER_SAVE_CONFIG, saveConfigMapper),
    takeLatest(
      actionTypes.AUTO_DIALER_SAVE_MESSAGE_TEMPLATE,
      saveMessageTemplate
    ),
    takeLatest(
      actionTypes.AUTO_DIALER_DELETE_MESSAGE_TEMPLATE,
      deleteMessageTemplate
    ),
    takeLatest(
      actionTypes.AUTO_DIALER_GET_USER_MESSAGE_TEMPLATES,
      getUserMessageTemplates
    ),
    takeLatest(
      actionTypes.AUTO_DIALER_START_SAVE_USER_MESSAGE_TEMPLATE,
      startSaveUserMessageTemplate
    ),
    takeLatest(actionTypes.AUTO_DIALER_START_BULK_SCHEDULE, startBulkSchedule),
  ]);
}
