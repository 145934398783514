import apiResource from "@config/resource";
import { fullConfig, voiceList } from "./data";

export const getConfig = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.autoDialer}/configuration/full*`))
    .reply(200, fullConfig);
};

export const getVoiceList = (mock) => {
  mock
    .onGet(new RegExp(`${apiResource.autoDialer}/voice*`))
    .reply(200, voiceList);
};

export const setActiveStatus = (mock) => {
  mock
    .onPut(new RegExp(`${apiResource.autoDialer}/configuration/activate*`))
    .reply(200, voiceList);
};

export default [
  // setActiveStatus,
  // getConfig,
  // getVoiceList,
];
