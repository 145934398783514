import { cacheBuster } from "@helpers/collection";

export const constructedTipMessage = (tip) => {
  return {
    message: tip.message || tip,
    linkUrl: tip.linkUrl || "",
    linkPage: tip.linkPage || "",
  };
};

export const getConfigUrl = (key) => {
  const fileName = key + "-tutorial.json?" + cacheBuster();
  const configUrl = new URL(fileName, process.env.TUTORIAL_URL);
  return configUrl.href;
};

export const getImageUrl = (fileName) => {
  const fileUrl = new URL(fileName, process.env.TUTORIAL_URL);
  return fileUrl.href;
};
